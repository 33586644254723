import { map, Observable } from 'rxjs';

import { Injectable, inject } from '@angular/core';

import { RecordsPage } from '@bp/shared/models/common';
import {
	assertIsMetadataQueryParamsBased, DTO, IEntitiesFirebaseApiService
} from '@bp/shared/models/metadata';
import { uuid } from '@bp/shared/utilities/core';

import { EnvironmentService } from '@bp/frontend/services/environment';
import {
	CrmLead, CrmLeadsQueryParams, WriteCrmLeadApiRequest, CRM_LEADS_COLLECTION_PATH, WriteCrmLeadApiRequestResult, WRITE_CRM_LEAD_FB_FN
} from '@bp/frontend/domains/crm/leads/models';
import { FirebaseService } from '@bp/frontend/features/firebase';
import { FirebaseDocumentWriter } from '@bp/frontend/features/firebase/models';

@Injectable({
	providedIn: 'root',
})
export class CrmLeadsApiService implements IEntitiesFirebaseApiService<CrmLead, CrmLeadsQueryParams> {

	private readonly __environment = inject(EnvironmentService);

	private readonly __firebaseService = inject(FirebaseService);

	readonly collectionPath = `bp/${ this.__environment.name }/${ CRM_LEADS_COLLECTION_PATH }`;

	private __previousWriteRequestId: string | null = null;

	readonly factory = (dto: DTO<CrmLead>): CrmLead => new CrmLead(dto);

	listenToQueriedRecordsPageChanges(query?: CrmLeadsQueryParams): Observable<RecordsPage<CrmLead>> {
		assertIsMetadataQueryParamsBased(query);

		return this.__firebaseService.listenToQueriedRecordsPageChanges(
			this.collectionPath,
			query,
			this.factory,
		);
	}

	get(id: string): Observable<CrmLead | null> {
		return this.__firebaseService
			.getDocument(`${ this.collectionPath }/${ id }`, this.factory);
	}

	write(lead: CrmLead): Observable<WriteCrmLeadApiRequestResult> {
		if (!lead.email)
			throw new Error('Cannot save a lead without an email');

		const previousWriteRequestId = this.__previousWriteRequestId;
		const writeRequestId = this.__previousWriteRequestId = uuid();

		return this.__firebaseService
			.callPostCloudFunction<WriteCrmLeadApiRequest, WriteCrmLeadApiRequestResult>(
			`${ WRITE_CRM_LEAD_FB_FN }${ this.__environment.firebaseFunctionSuffix }`,
			new WriteCrmLeadApiRequest({
				previousWriteRequestId,
				writeRequestId,
				writer: FirebaseDocumentWriter.frontend,
				environment: this.__environment.environment,
				data: lead,
			}),
		)
			.pipe(map(dto => WriteCrmLeadApiRequestResult.parseStrict(dto)));
	}

	delete(lead: CrmLead): Observable<void> {
		return this.__firebaseService.delete(`${ this.collectionPath }/${ lead.id }`);
	}

	save(_entity: CrmLead): Observable<CrmLead> {
		throw new Error('Method not implemented.');
	}

}
