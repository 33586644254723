import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TestimonialDto } from '../../../../models';

@Component({
	selector: 'bp-carousel-testimonial',
	templateUrl: './carousel-testimonial.component.html',
	styleUrls: [ './carousel-testimonial.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselTestimonialComponent {

	@Input() testimonial!: TestimonialDto;

}
