<form class="promo-form" (submit)="submit($event)">
	<mat-form-field appearance="outline" class="input-with-suffix-button-lg">
		<mat-label *ngIf="!noFloatLabel">
			{{ (media.greaterThan$('Phone') | async) ? fullPlaceholder : shortPlaceholder }}
		</mat-label>

		<input
			type="text"
			matInput
			autocomplete="email"
			bpTrimOnPaste
			tabindex="-1"
			[formControl]="emailCtrl"
			[placeholder]="
				noFloatLabel ? ((media.greaterThan$('Phone') | async) ? fullPlaceholder : shortPlaceholder) : ''
			"
		/>

		<bp-pending-btn
			matSuffix
			type="submit"
			class="get-started-button promo-flat-button"
			bpGoogleTagOnClick="promo_email-contact-form_cta_click"
			[pending]="pending"
		>
			<span class="label">{{ buttonText }}</span>
		</bp-pending-btn>

		<mat-error [bpFieldError]="emailCtrl" />
	</mat-form-field>
</form>
