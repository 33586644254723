import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

import { FADE, SLIDE } from '@bp/frontend/animations';
import { SharedDirectivesCoreModule } from '@bp/frontend/directives/core';

import { ImgUploadBtnComponent } from '../img-upload-btn';

@Component({
	selector: 'bp-file-upload-btn',
	standalone: true,
	imports: [
		CommonModule,
		MatLegacyButtonModule,
		MatLegacyProgressBarModule,
		MatIconModule,
		SharedDirectivesCoreModule,
	],
	templateUrl: './file-upload-btn.component.html',
	styleUrls: [ './file-upload-btn.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE, FADE ],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: FileUploadBtnComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: FileUploadBtnComponent,
			multi: true,
		},
	],
})
export class FileUploadBtnComponent extends ImgUploadBtnComponent {

	uploadFile(): void {
		this.filePicker.click();
	}

	override onClick(): void {
		// Does nothing
	}

}
