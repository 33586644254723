import { inject, OnInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';

import { Validators } from '@bp/shared/features/validation/models';
import { attrBoolValue } from '@bp/shared/utilities/core';

import { MediaService } from '@bp/frontend/features/media';

@Component({
	selector: 'bp-email-contact-form',
	templateUrl: './email-contact-form.component.html',
	styleUrls: [ './email-contact-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailContactFormComponent implements OnInit {

	readonly media = inject(MediaService);

	@Input() noFloatLabel!: boolean | '';

	@Input() buttonText = 'Start now';

	@Input() pending = false;

	@Output() readonly submitted = new EventEmitter<string>();

	shortPlaceholder = 'Your email';

	fullPlaceholder = 'Enter your work email';

	emailCtrl = new FormControl<string>('', [ Validators.customRequired('email'), Validators.email() ]);

	ngOnInit(): void {
		this.noFloatLabel = attrBoolValue(this.noFloatLabel);
	}

	setErrors(errors: ValidationErrors | null): void {
		this.emailCtrl.setErrors(errors);
	}

	submit(event: Event): void {
		event.preventDefault();

		this.emailCtrl.updateValueAndValidity();

		if (this.emailCtrl.valid)
			this.submitted.next(this.emailCtrl.value!.toLowerCase());
		else {
			this.emailCtrl.markAsDirty();

			this.emailCtrl.markAsTouched();

			this.emailCtrl.updateValueAndValidity();
		}
	}

}
