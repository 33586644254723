import { Directive, HostListener, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { attrBoolValue, isEmpty } from '@bp/shared/utilities/core';

import { YoutubePlayerDialogComponent, YoutubePlayerDialogData } from '../components';

@Directive({
	selector: '[bpOpenYoutubePlayerDialogOnClick]',
})
export class OpenYoutubePlayerDialogOnClickDirective {

	private _youtubeVideoSrc!: string;

	@Input()
	set bpOpenYoutubePlayerDialogOnClick(value: string) {
		if (isEmpty(value))
			throw new Error('The bpOpenYoutubePlayerDialogOnClick directive must be assigned a video source identifier.');

		this._youtubeVideoSrc = value;
	}

	private _loop = false;

	@Input()
	set bpOpenYoutubePlayerDialogOnClickLooped(value: boolean | '') {
		this._loop = attrBoolValue(value);
	}

	constructor(private readonly _dialog: MatDialog) {
	}

	@HostListener('click')
	onClick(): void {
		this._dialog
			.open<YoutubePlayerDialogComponent, YoutubePlayerDialogData>(
			YoutubePlayerDialogComponent,
			{
				data: {
					src: this._youtubeVideoSrc,
					loop: this._loop,
				},
			},
		);
	}

}
