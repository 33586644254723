import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { SLIDE } from '@bp/frontend/animations';

@Component({
	selector: 'bp-toggable-section',
	templateUrl: './toggable-section.component.html',
	styleUrls: [ './toggable-section.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE ],
})
export class ToggableSectionComponent {

	@Input()
	@HostBinding('class.open')
	open = false;

}
