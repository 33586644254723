import { Component, ChangeDetectionStrategy, Inject, TemplateRef } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { YoutubePlayerComponent } from '../youtube-player';

export type YoutubePlayerDialogData = Pick<YoutubePlayerComponent, 'loop' | 'src'> & { customThumbnailTpl?: TemplateRef<any> };

@Component({
	selector: 'bp-youtube-player-dialog',
	templateUrl: './youtube-player-dialog.component.html',
	styleUrls: [ './youtube-player-dialog.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YoutubePlayerDialogComponent {

	constructor(
		public dialogRef: MatDialogRef<YoutubePlayerDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: YoutubePlayerDialogData,
	) {
		this.dialogRef.addPanelClass('youtube-player-dialog-overlay-pane');
	}

}
