import { fromEvent, Subject } from 'rxjs';

import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Output, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

import { FADE, SLIDE } from '@bp/frontend/animations';
import { takeUntilDestroyed } from '@bp/frontend/models/common';
import { SharedPipesModule } from '@bp/frontend/pipes';

import { FirestoreUploadControlComponent } from '../firestore-upload-control';

@Component({
	selector: 'bp-img-upload-btn',
	standalone: true,
	imports: [
		CommonModule,
		MatLegacyButtonModule,
		MatLegacyProgressBarModule,
		SharedPipesModule,
	],
	templateUrl: './img-upload-btn.component.html',
	styleUrls: [ './img-upload-btn.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE, FADE ],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: ImgUploadBtnComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: ImgUploadBtnComponent,
			multi: true,
		},
	],
})
export class ImgUploadBtnComponent extends FirestoreUploadControlComponent implements OnInit {

	@ViewChild('filePicker', { static: true }) filePickerRef!: ElementRef<HTMLInputElement>;

	@Output('busy') override readonly busy$ = new Subject();

	get filePicker() {
		return this.filePickerRef.nativeElement;
	}

	get file() {
		return this.filePicker.files?.[0];
	}

	isExceededAllowedSize = false;

	ngOnInit() {
		fromEvent(this.filePicker, 'change')
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => {
				this.isExceededAllowedSize = !!(this.file && (this.file.size > (15 * 1024 * 1024)));

				if (!this.isExceededAllowedSize && this.file) {
					this.isPerformUploading$.next(true);

					void this._firebase.upload(this.file, this.bucketPath);
				}

				this._cdr.detectChanges();
			});
	}

	override validate() {
		return this.isExceededAllowedSize ? { exceededAllowedSize: true } : null;
	}

	@HostListener('click')
	onClick() {
		this.filePicker.click();
	}

}
