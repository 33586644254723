import { Directive, TemplateRef } from '@angular/core';

@Directive({
	selector: '[bpCustomThumbnail]',
})
export class CustomThumbnailDirective {

	constructor(public templateRef: TemplateRef<any>) {
	}

}
