import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'bp-universal-error-page',
	templateUrl: './universal-error-page.component.html',
	styleUrls: [ './universal-error-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniversalErrorPageComponent {

}
