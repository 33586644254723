import { Control, DTO, Default, FieldControlType, Label, Required, Validator } from '@bp/shared/models/metadata';
import { Countries } from '@bp/shared/models/countries';
import { Validators } from '@bp/shared/features/validation/models';

import { CrmLead } from '@bp/frontend/domains/crm/leads/models';

export class GetStartedFormCrmLeadVm extends CrmLead {

	static {
		this._initClassMetadata();
	}

	@Default('')
	@Label('Work email')
	override email?: string;

	@Validator(Validators.compose([
		Validators.required,
		Validators.xssSafe,
		Validators.noUrl,
	])!)
	override fullName!: string;

	@Default('')
	@Label('Phone number')
	@Control(
		FieldControlType.phone,
		{
			required: false,
			nativeAutocomplete: 'tel',
			typeControlOptions: {
				initialDialCodeCountry: Countries.get('US'),
			},
		},
	)
	override phone!: string;

	@Required()
	@Default('')
	override companyWebsite?: string;

	constructor(dto?: DTO<GetStartedFormCrmLeadVm> & { phone?: string }) {
		super(dto);

		this.phone ||= 'Not provided';

		this.lastName ||= 'Not provided';
	}

}
