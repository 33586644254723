import { CreateTypes, Options } from 'canvas-confetti';

export function fireConfetti(confetti: CreateTypes): void {
	internalFireConfetti(0.25, {
		spread: 26,
		startVelocity: 35,
	});

	internalFireConfetti(0.2, {
		spread: 60,
		startVelocity: 25,
	});

	internalFireConfetti(0.35, {
		spread: 100,
		decay: 0.91,
		startVelocity: 35,
	});

	internalFireConfetti(0.1, {
		spread: 120,
		startVelocity: 15,
		decay: 0.92,
	});

	internalFireConfetti(0.1, {
		spread: 120,
		startVelocity: 25,
	});

	function internalFireConfetti(particleRatio: number, options: Options): void {
		void confetti({
			origin: { y: 0.7 },
			colors: [ '#2578ea', '#33ff99', '#9999ff', '#ff6666' ],
			zIndex: 99_999,
			...options,
			particleCount: Math.floor(400 * particleRatio),
			ticks: 60,
			scalar: 0.5,
		});
	}
}
