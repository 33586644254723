<div class="title-wrapper" (click)="open = !open">
	<div class="title-text-wrapper">
		<ng-content select=".title" />
	</div>

	<bp-picture src="/assets/shared/toggable-section/state-arrow" svg [class.open]="open" />
</div>

<div *ngIf="open" class="data-wrapper" @slide>
	<div class="spacer">
		<ng-content select=".data" />
	</div>
</div>
