<h1 *ngIf="headliner; else titleTpl" class="title mat-headline">{{ header }}</h1>

<ng-template #titleTpl>
	<div class="title mat-title">{{ header }}</div>
</ng-template>

<div class="description">{{ description }}</div>

<a
	*ngIf="btnRouterLink; else wizardLinkTpl"
	mat-flat-button
	class="promo-flat-button"
	color="primary"
	[routerLink]="btnRouterLink"
	[fragment]="btnRouterLinkFragment"
>
	{{ btnRouterLinkText ? btnRouterLinkText : 'Start now' }}
</a>

<ng-template #wizardLinkTpl>
	<bp-call-to-action-button classList="promo-flat-button" [bpGoogleTagOnClick]="callToActionGaEvent" />
</ng-template>
