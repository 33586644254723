<bp-svg-icon name="logo" />

<div class="mat-headline mb-4">
	<div *ngIf="headliner" class="work-smarter-text">{{ headliner }}</div>

	<ng-container *ngIf="textDirective; else inputHeadlinerTpl" [ngTemplateOutlet]="textDirective.tpl" />

	<ng-template #inputHeadlinerTpl>
		<div class="text">{{ text }}</div>
	</ng-template>
</div>

<div [bpGoogleTagOnClick]="callToActionGaEvent">
	<ng-container [ngTemplateOutlet]="actionButton?.tpl ?? defaultTpl" />
</div>

<ng-template #defaultTpl>
	<bp-call-to-action-button classList="promo-flat-button" />
</ng-template>
