<div class="category-block">
	<img class="icon" src="{{ card.categoryIconSrc }}" alt="{{ card.categoryIconAlt }}" />
	<div
		class="m-0 no-text-transform font-weight-bold"
		[ngClass]="{
			'mat-subheading-1': !previewMode,
			'mat-body': previewMode
		}"
	>
		{{ card.category.displayName }}
	</div>
</div>

<div
	class="title"
	[ngClass]="{
		'mat-subheading-2': !previewMode,
		'mat-body-strong': previewMode
	}"
>
	{{ card.title }}
</div>

<bp-picture
	*ngIf="showCover || previewMode"
	class="cover-img"
	responsive
	skipTablet
	[eager]="hasAnchorToUsecase || previewMode"
	[src]="card.coverImgSrc"
/>

<ng-container *ngIf="previewMode; else defaultModeTpl">
	<a class="link-with-arrow-after learn-more-link" routerLink="/usecases" [fragment]="card.category.name">
		Learn more
	</a>
</ng-container>

<ng-template #defaultModeTpl>
	<p class="content">{{ card.text }}</p>

	<a class="link-with-arrow-after learn-more-link" routerLink="/newsroom/rNHm7lZeOv9vAKrbLTSS">Learn more</a>
</ng-template>
