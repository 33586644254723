import type { UsecaseCardDto } from '../models';
import { UsecaseCategory } from '../models';

const ASSETS_DIR = '/assets/shared/usecase-cards';

export const USECASES_CARDS_DTO: UsecaseCardDto[] = [
	{
		title: 'Scaling a global ecommerce business doesn’t have to be so hard.',
		category: UsecaseCategory.eCommerce,
		text: 'Entering new countries requires integration with payment providers, local entities, banks, FX, and compliance vendors. BridgerPay eliminates the complexity and costs of managing a piecemeal approach of multiple vendors and systems.',
		categoryIconSrc: `${ ASSETS_DIR }/category-icon-ecommerce.svg`,
		coverImgSrc: `${ ASSETS_DIR }/ecommerce`,
		categoryIconAlt: 'e-commerce icon',
		coverImgAlt: 'BridgerPay Checkout with an e-commerce website in the background',
		learnMoreRouterLink: '/newsroom',
	},
	{
		title: 'BridgerPay makes it easy to scale local travel businesses in any market worldwide.',
		category: UsecaseCategory.travel,
		text: 'Your customers can book and pay for a plane ticket with a simple click of a button. Integrate once with all of your travel payment providers so that your clients can enjoy a quick and seamless payment process.',
		categoryIconSrc: `${ ASSETS_DIR }/category-icon-travel.svg`,
		coverImgSrc: `${ ASSETS_DIR }/travel`,
		categoryIconAlt: 'travel icon',
		coverImgAlt: 'Airplane with a secure payment window',
		learnMoreRouterLink: '/newsroom',
	},
	{
		title: 'Optimize your Gross Gaming Revenue (GGR)',
		category: UsecaseCategory.gaming,
		text: 'Boost your conversions with local payment acceptance and checkout experiences. A single API gives you access to over 100 countries and 900 payment types. Our technology supports OCT/CFT payouts, refunds and partial refunds for gaming businesses.',
		categoryIconSrc: `${ ASSETS_DIR }/category-icon-gaming.svg`,
		coverImgSrc: `${ ASSETS_DIR }/gaming`,
		categoryIconAlt: 'gaming icon',
		coverImgAlt: 'Pixeled payment providers in a 2D game',
		learnMoreRouterLink: '/newsroom',
	},
	{
		title: 'SaaS your payments',
		category: UsecaseCategory.saas,
		text: 'Get your software subscription payments seamlessly - more approved transactions, faster and higher conversions, unbeatable insights, and flexibility.',
		categoryIconSrc: `${ ASSETS_DIR }/category-icon-saas.svg`,
		coverImgSrc: `${ ASSETS_DIR }/saas`,
		categoryIconAlt: 'subscriptions icon',
		coverImgAlt: 'Checkout widget with icons of SaaS companies',
		learnMoreRouterLink: '/newsroom',
	},
	{
		title: 'Reduce cart abandonment rates with local payment acceptance and checkout experiences',
		category: UsecaseCategory.marketplaces,
		text: 'Accept payments globally with local ewallets, bank transfers, cash, credit and debit cards. Speed up time to market with Bridger Checkout, a low-code, online checkout payment UI to speed up payment deployment across the globe.',
		categoryIconSrc: `${ ASSETS_DIR }/category-icon-marketplaces.svg`,
		coverImgSrc: `${ ASSETS_DIR }/marketplaces`,
		categoryIconAlt: 'marketplace icon',
		coverImgAlt: 'Various products with Amazon and eBay icons',
		learnMoreRouterLink: '/newsroom',
	},
];
