import { Enumeration } from '@bp/shared/models/core/enum';

export class WriteCrmLeadApiRequestResult extends Enumeration {

	static readonly alreadyConverted = new WriteCrmLeadApiRequestResult();

	static readonly newRecordWasCreated = new WriteCrmLeadApiRequestResult();

	static readonly existingRecordWasUpdated = new WriteCrmLeadApiRequestResult();

}
