<button
	mat-stroked-button
	class="bp-round-button bp-button-md"
	type="button"
	color="primary"
	[disabled]="(uploadProgress$ | async) !== null"
>
	<mat-progress-bar
		*ngIf="uploadProgress$ | async as progress"
		color="accent"
		mode="determinate"
		@slide
		[value]="progress"
	/>
	<ng-content />
</button>

<div
	*ngIf="(uploadedDownloadUrl$ | async) || value as url"
	class="thumbnail"
	@fade
	[style.background-image]="'url(' + url + ')' | safe: 'style'"
></div>

<div *ngIf="(uploadError$ | async) || isExceededAllowedSize" class="error" @slide>
	{{ uploadError$ | async }}
	<span *ngIf="isExceededAllowedSize">The maximum allowed size of the file is 15mb</span>
</div>

<input #filePicker type="file" accept=".png,.jpg,.svg,.webp" [disabled]="(canUpload$ | async) === false" />
