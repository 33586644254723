import { chunk } from 'lodash-es';

import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedFeaturesMediaModule } from '@bp/frontend/features/media';
import { SharedPipesModule } from '@bp/frontend/pipes';
import { SharedComponentsCoreModule } from '@bp/frontend/components/core';

import { mapLogoNameToAltText } from '@bp/promo/shared/core';

@Component({
	selector: 'bp-companies-tiles[companiesTiles]',
	standalone: true,
	imports: [
		CommonModule,

		SharedPipesModule,
		SharedComponentsCoreModule,
		SharedFeaturesMediaModule,
	],
	templateUrl: './companies-tiles.component.html',
	styleUrls: [ './companies-tiles.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompaniesTilesComponent implements OnInit {

	protected readonly _mapLogoNameToAltText = mapLogoNameToAltText;

	@Input() assetsDir = '/assets/shared/companies-logos';

	@Input() companiesTiles!: string[];

	/** Count of tiles to split companiesTiles by chunks for endless animation */
	@Input() tilesChunkSize = 9;

	/**
	 * 	Animation consists of two blocks. This parameter specifies how many tiles chunks will be repeated
	 * 	in one such block. Increasing this parameter - increases animation speed.
	 * 	Animation speed can be configured via animationCycleDuration parameter (by default 25s)
	 * 	*/
	@Input() tilesChunksBlockSize = 1;

	@Input()
	@HostBinding('style.--animation-cycle-duration') animationCycleDuration = '25s';

	protected _animatedCompaniesTilesLines: string[][] = [];

	ngOnInit(): void {
		this._animatedCompaniesTilesLines = chunk(this.companiesTiles, this.tilesChunkSize);
	}

}
