import type { OnInit } from '@angular/core';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { LegalPage } from '../../models';

@Component({
	selector: 'bp-legal-pages-links',
	templateUrl: './legal-pages-links.component.html',
	styleUrls: [ './legal-pages-links.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegalPagesLinksComponent implements OnInit {

	@Input() except!: LegalPage;

	legalPages!: LegalPage[];

	ngOnInit(): void {
		this.legalPages = LegalPage.getList().filter(v => v !== this.except);
	}

}
