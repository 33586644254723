import { inject, OnInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { attrBoolValue } from '@bp/shared/utilities/core';

import { UsecaseCardDto, UsecaseCategory } from '../../models';

@Component({
	selector: 'bp-usecase-card',
	templateUrl: './usecase-card.component.html',
	styleUrls: [ './usecase-card.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsecaseCardComponent implements OnInit {

	@Input('src') card!: UsecaseCardDto;

	@Input() showCover = true;

	@HostBinding('attr.id')
	get id(): string {
		return this.card.category.name;
	}

	@Input()
	@HostBinding('class.preview-mode')
	previewMode!: boolean | '';

	private readonly _host = inject<ElementRef<HTMLElement>>(ElementRef<HTMLElement>);

	private readonly _route = inject(ActivatedRoute);

	$host = this._host.nativeElement;

	hasAnchorToUsecase = !!UsecaseCategory.parse(this._route.snapshot.fragment!);

	ngOnInit(): void {
		this.previewMode = attrBoolValue(this.previewMode);
	}

}
