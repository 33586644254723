import { Enumeration } from '@bp/shared/models/core/enum';

export class LegalPage extends Enumeration {

	static subscriptionTerms = new LegalPage('Subscription terms');

	static cookiesPolicy = new LegalPage('Cookies policy');

	static privacyPolicy = new LegalPage('Privacy policy');

	static termsOfService = new LegalPage('Terms of service');

	static prohibitedBusinesses = new LegalPage('Prohibited businesses');

	static bugBountyProgram = new LegalPage('Bug bounty program');

}
