import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'bp-call-to-action-button',
	templateUrl: './call-to-action-button.component.html',
	styleUrls: [ './call-to-action-button.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallToActionButtonComponent {

	@Input() classList?: string;

}
