import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'bp-review-face',
	templateUrl: './review-face.component.html',
	styleUrls: [ './review-face.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewFaceComponent {

	@Input() face!: string;

	@Input() face2x!: string;

	@Input() logo!: string | null;

	@Input() logo2x!: string | null;

	@Input() alt?: string;

	@Input() halo = true;

}
