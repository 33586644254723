/// <reference path="../../../../../../../typings.d.ts" />

// eslint-disable-next-line @typescript-eslint/naming-convention
import Parallax, { ParallaxOptions } from 'parallax-js';
import { fromEvent } from 'rxjs';

import type { OnDestroy, OnInit } from '@angular/core';
import { Directive, ElementRef, Input } from '@angular/core';

import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { ZoneService } from '@bp/frontend/rxjs';

@Directive({
	selector: '[bpParallax]',
})
export class ParallaxDirective extends Destroyable implements OnInit, OnDestroy {

	@Input('bpParallax') options?: ParallaxOptions | '';

	$host = <HTMLElement> this._elementRef.nativeElement;

	private _parallaxInstance?: Parallax;

	private readonly _defaultOptions: ParallaxOptions = {
		scalarX: 15,
		scalarY: 15,
		invertX: false,
		invertY: false,
	};

	constructor(
		private readonly _elementRef: ElementRef,
		private readonly _zoneService: ZoneService,
	) {
		super();
	}

	ngOnInit() {
		this._parallaxInstance = this._zoneService.runOutsideAngular(() => new Parallax(this.$host, {
			...this._defaultOptions,
			...this.options,
		}));

		this._addParallaxEffectOnScroll();
	}

	ngOnDestroy() {
		this._parallaxInstance?.destroy();
	}

	private _addParallaxEffectOnScroll() {
		fromEvent(window, 'scroll')
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => void this._shiftElementsOnAxisYRelativeToScrollY());
	}

	private _shiftElementsOnAxisYRelativeToScrollY() {
		if (!this._parallaxInstance)
			return;

		let parentOffsetTop = (<HTMLLIElement | null> this.$host.offsetParent)?.offsetTop ?? 0;

		parentOffsetTop = parentOffsetTop > 0 ? parentOffsetTop : 0;
		const halfOfViewportHeight = parentOffsetTop > window.innerHeight ? window.innerHeight / 2 : 0;
		const scrollYRelativeToOffsetParent = (parentOffsetTop - window.scrollY) * -1 + halfOfViewportHeight;

		if (scrollYRelativeToOffsetParent > 0)
			this._parallaxInstance.calibrationY = Math.min(scrollYRelativeToOffsetParent / 150, 3);
	}

}
