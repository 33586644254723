import { fromEvent, map, switchMap, takeUntil } from 'rxjs';

import { Directive, ElementRef, inject, OnInit } from '@angular/core';

import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';

@Directive({ selector: '[bpHorizontalDrag]' })
export class HorizontalDragDirective extends Destroyable implements OnInit {

	private readonly __$host = <HTMLElement> inject(ElementRef).nativeElement;

	ngOnInit(): void {
		this.__dragHostElementWhenMouseDown();
	}

	private __dragHostElementWhenMouseDown(): void {
		fromEvent<MouseEvent>(this.__$host, 'mousedown')
			.pipe(
				map((mouseDownEvent: MouseEvent) => {
					mouseDownEvent.preventDefault();

					return {
						mouseCoordX: mouseDownEvent.x,
						originScrollX: this.__$host.scrollLeft,
					};
				}),
				switchMap(initialCoordinates => fromEvent<MouseEvent>(this.__$host, 'mousemove').pipe(
					map(mouseMoveEvent => <const>[ initialCoordinates, mouseMoveEvent ]),
					takeUntil(fromEvent(this.__$host, 'mouseup')),
				)),
				takeUntilDestroyed(this),
			)
			.subscribe(([ initialCoordinates, mouseMoveEvent ]) => {
				const { mouseCoordX, originScrollX } = initialCoordinates;
				const deltaX = mouseMoveEvent.x - mouseCoordX;

				this.__$host.scrollTo({ left: originScrollX - deltaX });
			});
	}

}
