<div class="wrapper">
	<ng-content />

	<div class="go-to-main-page-link-wrapper">
		<a class="link-with-arrow-before mat-body" routerLink="/">Go to Main Page</a>
	</div>

	<div class="broken-card-img-wrapper">
		<bp-picture src="/assets/error/broken-card" />
	</div>
</div>
