<div *bpIfGreaterThan="'PhoneLg'; else animatedLinesTpl" class="company-tiles-row">
	<ng-container
		*ngFor="let imgName of companiesTiles"
		[ngTemplateOutlet]="companyTileTpl"
		[ngTemplateOutletContext]="{ $implicit: imgName }"
	/>
</div>

<ng-template #animatedLinesTpl>
	<div *ngFor="let line of _animatedCompaniesTilesLines" class="company-tiles-row">
		<div *ngFor="let block of tilesChunksBlockSize * 2 | range" class="company-tiles-block">
			<ng-container
				*ngFor="let imgName of line"
				[ngTemplateOutlet]="companyTileTpl"
				[ngTemplateOutletContext]="{ $implicit: imgName }"
			/>
		</div>
	</div>
</ng-template>

<ng-template #companyTileTpl let-imgName>
	<bp-picture src="{{ assetsDir }}/{{ imgName }}" [alt]="_mapLogoNameToAltText(imgName)" />
</ng-template>
