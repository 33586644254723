<div class="testimonial">
	<img
		class="logo mb-1 {{ testimonial.logoName }}"
		srcset="{{testimonial.logo}} 1x, {{testimonial.logo2x}} 2x"
		alt="{{ testimonial.alt }}"
	/>

	<p class="text">“{{ testimonial.shortText ?? testimonial.text | bpTruncate: 300 : '...' }}”</p>

	<p class="name-position">
		<strong>
			<span class="mr-1">{{ testimonial.name }}</span>
			|
			<span class="ml-1">{{ testimonial.position }}</span>
		</strong>
	</p>
</div>
