import { TestimonialLogoDto } from '../models';

import { mapLogoNameToAltText } from './companies-logos-alt-mapping';
import { LOGOS_ASSETS_DIR } from './paths';

export const buildTestimonialLogoData = (logoName: string): TestimonialLogoDto => ({
	logoName,
	logo: `${ LOGOS_ASSETS_DIR }/${ logoName }.png`,
	logo2x: `${ LOGOS_ASSETS_DIR }/${ logoName }@2x.png`,
	alt: mapLogoNameToAltText(logoName),
});
