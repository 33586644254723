import { kebabCase } from 'lodash-es';

import {
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	Directive, inject,
	Input,
	OnInit,
	TemplateRef
} from '@angular/core';

import { ActionButtonDirective } from '../../../directives';

@Directive({
	selector: '[bpWorkSmarterText]',
	standalone: true,
})
export class WorkSmarterTextDirective {

	readonly tpl: TemplateRef<any> = inject(TemplateRef);

}

@Component({
	selector: 'bp-work-smarter',
	templateUrl: './work-smarter.component.html',
	styleUrls: [ './work-smarter.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkSmarterComponent implements OnInit {

	@Input() headliner: string | null = 'PAYMENT OPERATIONS';

	@Input() text = 'STARTING TODAY';

	@ContentChild(ActionButtonDirective) actionButton?: ActionButtonDirective;

	@ContentChild(WorkSmarterTextDirective) textDirective?: WorkSmarterTextDirective;

	callToActionGaEvent!: `promo_${ string }_cta_click`;

	ngOnInit(): void {
		this.callToActionGaEvent = `promo_${ kebabCase(this.headliner ?? this.text) }_cta_click`;
	}

}
