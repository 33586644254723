import { PlayWhenPartiallyInViewportAnimationStrategy } from './play-when-partially-in-viewport-animation-strategy';
import { RelatedToViewportIntersectionAnimationStrategy } from './related-to-viewport-intersection-animation-strategy';
import type { ILottieAnimationStrategy } from './lottie-animation-strategy';
import { NoAutoplayAnimationStrategy } from './no-autoplay-animation-strategy';

export type LottieAnimationStrategyName = 'noAutoplay' | 'playWhenFullyInViewport' | 'relatedToViewportIntersection';

export const lottieAnimationStrategyFactory = (strategy: LottieAnimationStrategyName): ILottieAnimationStrategy => {

	switch (strategy) {
		case 'playWhenFullyInViewport':
			return new PlayWhenPartiallyInViewportAnimationStrategy(0.9);

		case 'relatedToViewportIntersection':
			return new RelatedToViewportIntersectionAnimationStrategy();

		case 'noAutoplay':
			return new NoAutoplayAnimationStrategy();

		default:
			throw new Error('Invalid animation strategy');
	}

};
