import { isNumber } from 'lodash-es';
import { fromEvent, Subject } from 'rxjs';

import type { OnInit } from '@angular/core';
import {
	ChangeDetectionStrategy, Component, ElementRef, HostListener,
	Output, ViewChild
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { SLIDE } from '@bp/frontend/animations';
import { ControlComponent } from '@bp/frontend/components/core';
import { FirebaseService } from '@bp/frontend/features/firebase';
import { takeUntilDestroyed } from '@bp/frontend/models/common';

@Component({
	selector: 'bp-upload-link',
	templateUrl: './upload-link.component.html',
	styleUrls: [ './upload-link.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE ],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: UploadLinkComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: UploadLinkComponent,
			multi: true,
		},
	],
})
export class UploadLinkComponent extends ControlComponent implements OnInit {

	@ViewChild('filePicker', { static: true }) filePickerRef!: ElementRef<HTMLInputElement>;

	@Output('busy') readonly busy$ = new Subject();

	get filePicker() {
		return this.filePickerRef.nativeElement;
	}

	get file() {
		return this.filePicker.files?.[0];
	}

	isExceededAllowedSize = false;

	constructor(public firebaseService: FirebaseService) {
		super();

		this.firebaseService.uploadProgress$
			.pipe(takeUntilDestroyed(this))
			.subscribe(v => void this.busy$.next(isNumber(v)));

		this.firebaseService.uploadedDownloadUrl$
			.pipe(takeUntilDestroyed(this))
			.subscribe(v => {
				this.valueChange.emit(v);

				this.onChange(v);
			});
	}

	ngOnInit() {
		fromEvent(this.filePicker, 'change')
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => {
				this.isExceededAllowedSize = !!(this.file && (this.file.size > (15 * 1024 * 1024)));

				if (!this.isExceededAllowedSize && this.file)
					void this.firebaseService.upload(this.file, 'applicants-cv');

				this._cdr.detectChanges();
			});
	}

	override validate() {
		return this.isExceededAllowedSize ? { exceededAllowedSize: true } : null;
	}

	@HostListener('click')
	onClick() {
		this.filePicker.click();
	}
}
