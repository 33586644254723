import { ImgDefinition, TRUST_LOGOS_FOR_DARK_BLUE_BG, TRUST_LOGOS_FOR_WHITE_BG } from '@bp/frontend/models/business';

const TRUST_LOGOS_ASSETS_DIR = '/assets/shared/trust-logos';

const VISA_ON_THE_LIST_LOGO: ImgDefinition = {
	src: `${ TRUST_LOGOS_ASSETS_DIR }/visa-on-the-list`,
	alt: 'Visa On The List logo',
	svg: false,
};

export const PROMO_TRUST_LOGOS_FOR_DARK_BLUE_BG_LAPTOP = [ ...TRUST_LOGOS_FOR_DARK_BLUE_BG ];

PROMO_TRUST_LOGOS_FOR_DARK_BLUE_BG_LAPTOP.splice(2, 0, VISA_ON_THE_LIST_LOGO);

export const PROMO_TRUST_LOGOS_FOR_DARK_BLUE_BG_MOBILE = [ ...TRUST_LOGOS_FOR_DARK_BLUE_BG ];

export const PROMO_TRUST_LOGOS_FOR_WHITE_BG_LAPTOP = [ ...TRUST_LOGOS_FOR_WHITE_BG ];

PROMO_TRUST_LOGOS_FOR_WHITE_BG_LAPTOP.splice(2, 0, VISA_ON_THE_LIST_LOGO);

export const PROMO_TRUST_LOGOS_FOR_WHITE_BG_MOBILE = [ ...TRUST_LOGOS_FOR_WHITE_BG ];
