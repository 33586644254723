import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'bp-rate',
	templateUrl: './rate.component.html',
	styleUrls: [ './rate.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RateComponent {

	@Input() rate!: number;

	@Input() size = 16;

	@Input() icon?: string = '/assets/shared/testimonials/rate/star-rounded.svg';

}
