import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';

import { SharedPipesModule } from '@bp/frontend/pipes';

import { YoutubePlayerComponent, YoutubePlayerDialogComponent } from './components';
import { CustomThumbnailDirective, OpenYoutubePlayerDialogOnClickDirective } from './directives';

@NgModule({
	imports: [
		CommonModule,
		MatDialogModule,
		MatProgressBarModule,

		SharedPipesModule,
	],
	declarations: [
		YoutubePlayerComponent,
		YoutubePlayerDialogComponent,
		OpenYoutubePlayerDialogOnClickDirective,
		CustomThumbnailDirective,
	],
	exports: [
		YoutubePlayerComponent,
		OpenYoutubePlayerDialogOnClickDirective,
		CustomThumbnailDirective,
	],
})
export class SharedFeaturesYoutubePlayerModule {
}
