import { Dictionary } from '@bp/shared/typings';

const COMPANIES_NAMES_TO_ALT_TEXT_MAPPING: Dictionary<string> = {
	amex: 'American Express',
	astropay: 'AstroPay',
	betconstruct: 'BetConstruct',
	bigcommerce: 'BigCommerce',
	'blue-nile': 'Blue Nile',
	'blue-snap': 'BlueSnap',
	boxxport: 'BoxxPort',
	brown: 'Brown',
	'brown-circled': 'Brown',
	btobet: 'BtoBet',
	calypsa: 'Calypsa by ModLi',
	checkout: 'Checkout',
	codere: 'Codere',
	csmoney: 'CSMoney',
	ecopayz: 'EcoPayz',
	eightcap: 'Eightcap',
	equiti: 'Equiti',
	etihad: 'Etihad Airways',
	fattal: 'Fattal',
	gfg: 'Global Fashion Group',
	hoolah: 'Hoolah',
	ingenico: 'Ingenico',
	'james-allen': 'James Allen',
	'james-allen-short': 'James Allen',
	klarna: 'Klarna',
	leonardo: 'Leonardo',
	'luxury-escapes-row': 'LuxuryEscapes',
	'luxury-escapes-two-lines': 'LuxuryEscapes',
	magento: 'Magento',
	'magento-icon': 'Magento',
	'magento-row': 'Magento',
	mastercard: 'Mastercard',
	mc: 'Mastercard',
	'mc-icon': 'Mastercard',
	minihotel: 'Minihotel',
	nyx: 'NYX',
	'one-two-trip': 'One Two Trip',
	optima: 'Optima',
	'optima-squared': 'Optima',
	paybis: 'Paybis',
	paypal: 'PayPal',
	paysafe: 'Paysafe',
	'plan-your-trip-row': 'Plan Your Trip',
	playtech: 'Playtech',
	prestashop: 'PrestaShop',
	'prestashop-icon': 'PrestaShop',
	qoneco: 'Qoneco',
	'ritz-carlton': 'The Ritz-Carlton',
	'saxo-didge': 'Saxo-Didge',
	salesforce: 'Salesforce',
	shopify: 'Shopify',
	'shopify-icon': 'Shopify',
	skrill: 'Skrill',
	soft2bet: 'Soft2Bet',
	sportsevents: 'SportsEvents',
	stripe: 'Stripe',
	'thombo-foundation': 'Thombo Foundation',
	'tus-airways': 'Tus Airways',
	twitch: 'Twitch',
	vantage: 'VantageFx',
	visa: 'Visa',
	wix: 'Wix',
	'woo-commerce': 'WooCommerce',
	'woo-commerce-row': 'WooCommerce',
	wordpress: 'WordPress',
	'wordpress-icon': 'WordPress',
	worldline: 'Worldline',
	worldpay: 'Worldpay',
	'worldpay-flat': 'Worldpay',
	zalora: 'Zalora',
	'zalora-letter': 'Zalora',
	zanui: 'Zanui',
	zotapay: 'ZotaPay',

	'google-cloud': 'Google Cloud',
	intercom: 'Intercom',
	'log-rocket': 'LogRocket',
};

function mapLogoToCompanyName(logoName: string): string | null {
	return <string | undefined> COMPANIES_NAMES_TO_ALT_TEXT_MAPPING[logoName] ?? null;
}

export function mapLogoNameToAltText(name: string): string {
	const companyName = mapLogoToCompanyName(name);

	return companyName ? `${ companyName } logo` : '';
}
