import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { RouterModule } from '@angular/router';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { SharedDirectivesCoreModule } from '@bp/frontend/directives/core';
import { SharedFeaturesModalModule } from '@bp/frontend/features/modal';
import { SharedFeaturesSvgIconsModule } from '@bp/frontend/features/svg-icons';
import { TextMaskDirective } from '@bp/frontend/features/text-mask';
import { SharedFeaturesValidationModule } from '@bp/frontend/features/validation';
import { SharedFeaturesYoutubePlayerModule } from '@bp/frontend/features/youtube-player';
import { SharedFeaturesAnalyticsModule } from '@bp/frontend/features/analytics';
import { SharedPipesModule } from '@bp/frontend/pipes';
import { SharedFeaturesCarouselModule } from '@bp/frontend/features/carousel';
import { SharedFeaturesMediaModule } from '@bp/frontend/features/media';
import { SharedFeaturesMomentPipesModule } from '@bp/frontend/features/moment/pipes';
import { SharedFeaturesTooltipModule } from '@bp/frontend/features/tooltip';
import { SpinningLogoComponent } from '@bp/frontend/components/spinning-logo';
import { FileUploadBtnComponent } from '@bp/frontend/components/upload-btn';
import { SharedComponentsMetadataModule } from '@bp/frontend/components/metadata';

import {
	DescriptionBlockComponent, EmailContactFormComponent, LottieSceneComponent, RateComponent,
	ReviewFaceComponent, UniversalErrorPageComponent, UploadLinkComponent, UsecaseCardComponent,
	UsecasesPreviewCardComponent, WorkSmarterComponent, LegalPagesLinksComponent, ToggableCardComponent,
	ToggableSectionComponent, CarouselTestimonialsComponent, CarouselTestimonialComponent,
	CallToActionButtonComponent
} from './components';
import {
	ActionButtonDirective, HorizontalDragDirective, OverflowDetectorDirective, ParallaxDirective,
	PromoTooltipDirective
} from './directives';

const EXPOSED_MODULES = [
	CommonModule,
	MatButtonModule,
	MatFormFieldModule,
	MatInputModule,
	MatIconModule,
	MatSelectModule,

	SharedPipesModule,
	SharedDirectivesCoreModule,
	SharedComponentsCoreModule,
	SharedFeaturesModalModule,
	SharedFeaturesTooltipModule,
	SharedFeaturesValidationModule,
	TextMaskDirective,
	SharedFeaturesSvgIconsModule,
	SharedFeaturesYoutubePlayerModule,
	SharedFeaturesMomentPipesModule,
];

const EXPOSED_COMPONENTS = [
	LegalPagesLinksComponent,
	UsecaseCardComponent,
	UsecasesPreviewCardComponent,
	EmailContactFormComponent,
	DescriptionBlockComponent,
	RateComponent,
	ReviewFaceComponent,
	WorkSmarterComponent,
	ParallaxDirective,
	PromoTooltipDirective,
	UploadLinkComponent,
	UniversalErrorPageComponent,
	LottieSceneComponent,
	ToggableCardComponent,
	ToggableSectionComponent,
	CallToActionButtonComponent,
	OverflowDetectorDirective,
	CarouselTestimonialsComponent,
	HorizontalDragDirective,
	ActionButtonDirective,
];

const EXPOSED_STANDALONE = [
	SpinningLogoComponent,
];

@NgModule({
	imports: [
		EXPOSED_MODULES,
		EXPOSED_STANDALONE,
		ReactiveFormsModule,
		RouterModule,
		MatMenuModule,
		SharedFeaturesCarouselModule,
		SharedFeaturesAnalyticsModule,
		SharedFeaturesMediaModule,
		FileUploadBtnComponent,
		SharedComponentsMetadataModule,
	],
	exports: [
		EXPOSED_MODULES,
		EXPOSED_COMPONENTS,
		EXPOSED_STANDALONE,
	],
	declarations: [
		EXPOSED_COMPONENTS,
		CarouselTestimonialComponent,
	],
})
export class SharedModule {}
