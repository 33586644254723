import { TestimonialDto } from '../models';

import { TESTIMONIALS_ASSETS_DIR } from './paths';
import { buildTestimonialLogoData } from './testimonials-logo';

export const TESTIMONIALS: TestimonialDto[] = [
	{
		name: 'Yehoshua Fried',
		position: 'Co-founder & CTO, Calypsa',
		face: '',
		face2x: '',
		rate: 5,
		text: 'You integrate with BridgerPay once and you can connect hundreds of processors without wasting time on development. A year ago, if we wanted to change our Credit Card provider it would cost us $20k to integrate, now we can do it in seconds using BridgerPay’s Connections.',
		...buildTestimonialLogoData('calypsa'),
	},
	{
		name: 'Alejandro Stivelman',
		position: 'Chief Product Officer, PlanYourTrip',
		face: `${ TESTIMONIALS_ASSETS_DIR }/alejandro-stivelman.png`,
		face2x: `${ TESTIMONIALS_ASSETS_DIR }/alejandro-stivelman@2x.png`,
		rate: 5,
		text: 'BridgerPay has a great team of professionals who understand our business and technical needs perfectly. The main reasons we are working with BridgerPay are the great support from different departments in their company, literally 24/7, the ability to remember and handle every single request, and finally, their super positive attitude.',
		...buildTestimonialLogoData('plan-your-trip-row'),
	},
	{
		name: 'Maurice V.',
		position: 'Ecommerce IT solutions specialist, WooCommerce',
		face: `${ TESTIMONIALS_ASSETS_DIR }/maurice-replacer.png`,
		face2x: `${ TESTIMONIALS_ASSETS_DIR }/maurice-replacer@2x.png`,
		rate: 5,
		text: `The powerful BridgerPay Payments routing solution integration is the cornerstone of my great solution that I provide to my
		       clients that use it for Marketing & Sales alignment. It allows me to have a single source of truth for my clients’ data and
		       accurately measure the impact it gives for marketing campaigns on the sales pipeline.`,
		shortText: `The powerful BridgerPay Payments routing solution integration is the cornerstone of my great solution that I provide to my
		       clients that use it for Marketing & Sales alignment. It allows me to have a single source of truth for my clients’ data and
		       accurately measure the impact it gives.`,
		...buildTestimonialLogoData('woo-commerce'),
	},
	{
		name: 'Graham Hills',
		position: 'Head of Asia, Luxury Escapes',
		face: '',
		face2x: '',
		rate: 5,
		text: 'When the agreement with BridgerPay was signed, I had confidence to sign the new local payment partners as I could see the integration time would be quick. We now see up to 40% of orders in some months coming through these partners and what’s more exciting is that often more than 70% of the buyers are new buyers.',
		...buildTestimonialLogoData('luxury-escapes-row'),
	},
	{
		name: 'Kannan Rajaratnam',
		position: 'Regional Head of Payments, Zalora',
		face: `${ TESTIMONIALS_ASSETS_DIR }/kannan.png`,
		face2x: `${ TESTIMONIALS_ASSETS_DIR }/kannan@2x.png`,
		rate: 5,
		text: `Using BridgerPay didn't just pay off, the ROI is absolutely unbelievable. Everything about BridgerPay is excellent.
		       Speed of deployment, customization, customer care, and ease of use are top-notch. A platform like this is definitely new
		       and one of a kind. I’m glad that we approached BridgerPay, I am extremely pleased by the work done so far, and I can’t wait
		       to keep using BridgerPay in the future`,
		videoId: 'ee8wqNyZIQk',
		...buildTestimonialLogoData('zalora'),
	},
	{
		name: 'Yuval Shtokhamer',
		position: 'CEO & Co-Founder, MiniHotel',
		face: `${ TESTIMONIALS_ASSETS_DIR }/yuval.png`,
		face2x: `${ TESTIMONIALS_ASSETS_DIR }/yuval@2x.png`,
		rate: 5,
		text: `At MiniHotel, we found BridgerPay to be the standout choice with its multitude of gateways and
		payment service providers. Their excellent API, top-tier support, and personalized care from top to
		bottom made the decision easy. Operating in 70 countries, BridgerPay naturally fits our global
		clientele. My favorite feature? Tokenization – a game-changer for us at MiniHotel.`,
		...buildTestimonialLogoData('minohotel'),
	},
];
