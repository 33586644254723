import { map, startWith } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { MediaService } from '@bp/frontend/features/media';

import { USECASES_CARDS_DTO } from '../../data';
import type { UsecaseCardDto } from '../../models';
import { UsecaseCategory } from '../../models';

@Component({
	selector: 'bp-usecases-preview-cards',
	templateUrl: './usecases-preview-cards.component.html',
	styleUrls: [ './usecases-preview-cards.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsecasesPreviewCardComponent {

	@Input()
	@HostBinding('class')
	theme: 'dark' | 'light' = 'dark';

	usecasesCategories = [
		UsecaseCategory.eCommerce,
		UsecaseCategory.travel,
		UsecaseCategory.marketplaces,
	];

	tabletUsecasesCategories = [
		UsecaseCategory.eCommerce,
		UsecaseCategory.travel,
	];

	usecasesCardsDto$ = this._media.currentBreakpointChange$.pipe(
		startWith(null),
		map(() => this._getDtosAccordingToCurrentBreakpoint()),
	);

	constructor(private readonly _media: MediaService) { }

	private _getDtosAccordingToCurrentBreakpoint(): UsecaseCardDto[] {
		const breakpointCategories = this._media.tablet ? this.tabletUsecasesCategories : this.usecasesCategories;

		return USECASES_CARDS_DTO
			.filter(v => breakpointCategories.includes(v.category));
	}
}
