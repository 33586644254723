import { Directive } from '@angular/core';

import type { ILottieAnimationStrategy } from './lottie-animation-strategy';
import { BaseAnimationStrategy } from './base-animation-strategy';

@Directive() // To satisfy compiler
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class NoAutoplayAnimationStrategy extends BaseAnimationStrategy implements ILottieAnimationStrategy {

	override apply(): void {
		// Does nothing
	}

}
