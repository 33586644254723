import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MediaService } from '@bp/frontend/features/media';
import { CarouselArrowType } from '@bp/frontend/features/carousel';

import { TESTIMONIALS } from '../../data';
import { TestimonialDto } from '../../models';

@Component({
	selector: 'bp-carousel-testimonials',
	templateUrl: './carousel-testimonials.component.html',
	styleUrls: [ './carousel-testimonials.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselTestimonialsComponent {

	// eslint-disable-next-line @typescript-eslint/naming-convention
	CarouselArrowType = CarouselArrowType;

	testimonials = TESTIMONIALS;

	constructor(public readonly mediaService: MediaService) {
	}

	ensureTestimonialDto(value: any): TestimonialDto {
		return value;
	}

}
