<button
	type="button"
	mat-stroked-button
	color="primary"
	[disabled]="(uploadProgress$ | async) !== null"
	(click)="uploadFile()"
>
	<mat-progress-bar
		*ngIf="uploadProgress$ | async as progress"
		color="accent"
		mode="determinate"
		@slide
		[value]="progress"
	/>
	<ng-content />
</button>

<a *ngIf="(uploadedDownloadUrl$ | async) || value as url" class="ml-2 download" bpTargetBlank [href]="url">
	<mat-icon>download</mat-icon>
</a>

<div *ngIf="(uploadError$ | async) || isExceededAllowedSize" class="error" @slide>
	{{ uploadError$ | async }}
	<span *ngIf="isExceededAllowedSize">The maximum allowed size of the file is 15mb</span>
</div>

<input #filePicker type="file" [disabled]="(canUpload$ | async) === false" />
